.toggle-button {
  width: 250px;
  padding: 10px 8px;
  margin: 15px 0;
  font-family: inherit;
  background-color: dodgerblue;
  font-size: medium;
  opacity: 0.7;
  outline: none;
  border: 1px solid dodgerblue;
  box-shadow: 1px 1px 2px 3px #888888;
  color: #f7f7f7;
}

.toggle-button:hover {
  opacity: 0.8;
}

.toggle-button:active {
  opacity: 1;
  border: none;
  box-shadow: 1px 1px 1px 2px #888888;
}

form {
  text-align: center;
}

form input {
  font-family: inherit;
  padding: 12px 20px;
  margin: 10px;
  width: 70%;
  border-radius: 5px;
  outline: none;
  border: 1px solid gray;
  font-size: medium;
  font-style: bold;
  background-color: #f7f7f7;
  color: #575454;
}

form input:focus {
  border: 2px solid rgb(154, 187, 219);
  color: rgb(124, 169, 214);
}

form button {
  padding: 10px 8px;
  margin: 15px 0;
  font-family: inherit;
  background-color: dodgerblue;
  font-size: medium;
  opacity: 0.7;
  outline: none;
  border: 1px solid dodgerblue;
  box-shadow: 1px 1px 2px 3px #c4c0c0;
  color: #f7f7f7;
}

form button:hover {
  opacity: 0.8;
}

form button:active {
  opacity: 1;
  border: none;
  box-shadow: 1px 1px 1px 2px #b4b2b2;
}

.output {
  font-family: inherit;
  padding: 0px 10px;
  color: #575454;
}

.button-container {
  width: 100%;
  text-align: center;
}
.button-container button {
  padding: 10px 8px;
  margin: 15px 0;
  font-family: inherit;
  background-color: dodgerblue;
  font-size: medium;
  opacity: 0.7;
  outline: none;
  border: 1px solid dodgerblue;
  box-shadow: 1px 1px 2px 3px #c4c0c0;
  color: #f7f7f7;
}

.button-container button:hover {
  opacity: 0.8;
}

.button-container button:active {
  opacity: 1;
  border: none;
  box-shadow: 1px 1px 1px 2px #b4b2b2;
}

.button-saved {
  background-color: #28df99;
  font-size: x-small;
  border: none;
  outline: none;
  padding: 4px 8px;
  color: #f6f6f6;
}
